// Migrated
<template lang="pug">
.d-print-none
  .w-100.position-relative.z-3
    .position-absolute.bottom-0.right-0
      FavouriteIcon.position-relative.rounded-bottom-0.rounded-top.bg-white-blue(
        v-if="trip?.id"
        :id="trip.id"
      )
  PrettyTabsNav.pretty-tabs-layout-column.pretty-tabs-layout-md-row(
    v-if="calendar.departures && trip.sections && !isCharter",
    :tabs="navTabs"
    :locale-urls="localeURLs.tripSlug"
    @on-tab-change="onTabChange"
  )
  PrettyTabsNav.pretty-tabs-layout-column.pretty-tabs-layout-md-row(
    v-if="isCharter",
    :tabs="navTabs"
    :is-charter="isCharter"
    :locale-urls="localeURLs.destinationsId"
    @on-tab-change="onTabChange"
  )
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  emits: ['onTabChange'],

  computed: {
    ...mapState(useLocaleStore, ['isCharter']),
    ...mapState(useTripStore, {
      tripPath: 'tripPath',
      tripTab: 'tripTab',
      trip: 'trip',
      calendar: 'calendar'
    }),

    ...mapState(useCharterDestinationStore, {
      destination: 'charterDestination',
      destinationTitle: 'charterDestinationTitle',
      destinationTabs: 'charterDestinationTabs',
      destinationPath: 'charterDestinationPath',
    }),

    ...mapState(useCharterHotelStore, {
      hotel: 'charterHotel',
      hotelTab: 'charterHotelTab',
      hotelPath: 'charterHotelPath'
    }),

    ...mapState(useLocaleStore, [
      'localeURLs'
    ]),

    navTabs () {
      const tripLocaleUrls = this.localeURLs.tripSlug
      const destinationLocaleUrls = this.localeURLs.destinationsId
      // const hotelLocaleUrls = this.localeURLs.hotelId
      const isHotel = this.$route.name.startsWith('hotel-id')
      const isDestination = this.$route.name.startsWith('destination-id')

      if(!this.isCharter) {
        const tabs = [
          {
            text: 'tripTabItineraryText',
            to: this.tripPath,
            localeUrlKey: 'details',
            translationKey: 'tripTabItineraryText'
          },
          {
            text: 'tripTabCalendar',
            to: `${this.tripPath}${tripLocaleUrls.calendar}`,
            localeUrlKey: 'calendar',
            translationKey: 'tripTabCalendar'
          }
        ]

        const { sections } = this.trip
        if (sections.reviews_exists) {
          tabs.push({
            text: 'tripTabReviewsText',
            to: `${this.tripPath}${tripLocaleUrls.reviews}`,
            localeUrlKey: 'reviews',
            translationKey: 'tripTabReviewsText'
          })
        }

        if (sections?.hotels_exists && sections?.ships_exists) {
          tabs.push({
            text: 'tripTabHotelsAndShipsText',
            to: `${this.tripPath}${tripLocaleUrls.hotels}`,
            localeUrlKey: 'hotels',
            translationKey: 'tripTabHotelsAndShipsText'
          })
        } else if (sections?.hotels_exists) {
          tabs.push({
            text: 'tripTabHotelsText',
            to: `${this.tripPath}${tripLocaleUrls.hotels}`,
            localeUrlKey: 'hotels',
            translationKey: 'tripTabHotelsText'
          })
        } else if (sections?.ships_exists) {
          tabs.push({
            text: 'tripTabShipText',
            to: `${this.tripPath}${tripLocaleUrls.ships}`,
            localeUrlKey: 'ships',
            translationKey: 'tripTabShipText'
          })
        }

        if (sections?.films_exists) {
          tabs.push({
            text: 'tripTabFilmsText',
            to: `${this.tripPath}${tripLocaleUrls.films}`,
            localeUrlKey: 'films',
            translationKey: 'tripTabFilmsText'
          })
        }

        if (sections?.faq_exists) {
          tabs.push({
            text: 'tripTabFaqText',
            to: `${this.tripPath}${tripLocaleUrls.faq}`,
            localeUrlKey: 'faq',
            translationKey: 'tripTabFaqText'
          })
        }

        return tabs
      }

      if(this.isCharter && isDestination) {
        const tabs = [
          {
            text: 'destinationTabInfo',
            to: this.destinationPath,
            localeUrlKey: 'info',
            translationKey: 'destinationTabInfo'
          },
        ]

        const sections  = this.destinationTabs

        if (sections.includes("hotel_list")) {
          tabs.push({
            text: 'destinationTabHotel',
            to: `${this.destinationPath}${destinationLocaleUrls.hotel}`,
            localeUrlKey: 'hotel',
            translationKey: 'destinationTabHotel'
          })
        }

        if (sections.includes("areas_of_interest")) {
          tabs.push({
            text: 'destinationTabArea',
            to: `${this.destinationPath}${destinationLocaleUrls.area}`,
            localeUrlKey: 'area',
            translationKey: 'destinationTabArea'
          })
        }

        if (sections.includes("services")) {
          tabs.push({
            text: 'destinationTabServices',
            to: `${this.destinationPath}${destinationLocaleUrls.services}`,
            localeUrlKey: 'services',
            translationKey: 'destinationTabServices'
          })
        }

        if (sections.includes("beaches")) {
          tabs.push({
            text: 'destinationTabBeaches',
            to: `${this.destinationPath}${destinationLocaleUrls.beaches}`,
            localeUrlKey: 'beaches',
            translationKey: 'destinationTabBeaches'
          })
        }

        if (this.destination.tui_musement && sections.includes("activities")) {
          tabs.push({
            text: 'destinationTabActivities',
            to: `${this.destinationPath}${destinationLocaleUrls.activities}`,
            localeUrlKey: 'activities',
            translationKey: 'destinationTabActivities'
          })
        }

        if (sections.includes('weather')) {
          tabs.push({
            text: 'destinationTabWeather',
            to: `${this.destinationPath}${destinationLocaleUrls.weather}`,
            localeUrlKey: 'weather',
            translationKey: 'destinationTabWeather'
          })
        }

        return tabs
      }

      if(this.isCharter && isHotel) {
        const tabs = [
          {
            text: 'hotelTabInfo',
            to: this.hotelPath,
            localeUrlKey: 'info',
            translationKey: 'hotelTabInfo'
          },
        ]
        return tabs
      }
    }
  },

  methods: {
    onTabChange (newActiveTab) {
      this.$emit('onTabChange', newActiveTab)
    },
  }
})
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/nav-tabs";
.nav-item.favourite--tab{
  .favourite-icon {
    background: transparent !important;
  }
}
</style>
